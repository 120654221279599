<template>
  <MRow :gutter="0">
    <MCol>
      <FlotoFormItem
        id="subject-input"
        v-model="task.subject"
        rules="required|subject"
        auto-focus
        :label="$t('subject')"
        :placeholder="$t('subject')"
      />
      <MRow>
        <MCol :sm="6" :size="12">
          <FlotoFormItem
            id="technician-group-picker"
            :label="$tc('technician_group')"
            :placeholder="$tc('technician_group')"
          >
            <FlotoTechnicianGroupPicker v-model="task.groupId" as-input />
          </FlotoFormItem>
        </MCol>
        <MCol :sm="6" :size="12">
          <FlotoFormItem id="task-type-picker" :label="`${$tc('task_type')}`">
            <FlotoTaskTypePicker v-model="task.taskTypeId" as-input />
          </FlotoFormItem>
        </MCol>
      </MRow>
      <MRow>
        <MCol :sm="6" :size="12">
          <FlotoFormItem id="assigned-to-picker" :label="$t('assigned_to')">
            <FlotoTechnicianPicker
              v-model="task.technicianId"
              as-input
              mandatory-selection
              :group-id="task.groupId"
              change-technician-on-group-change
            />
          </FlotoFormItem>
        </MCol>
      </MRow>
      <MRow v-if="takeRange">
        <MCol>
          <FlotoFormItem
            id="start-date-picker"
            :label="`${$t('start')} ${$t('date')}`"
          >
            <FlotoDatePicker
              v-model="task.timeRange.startTime"
              :max-date="task.timeRange.endTime"
            />
          </FlotoFormItem>
        </MCol>
        <MCol>
          <FlotoFormItem
            id="end-date-picker"
            :label="`${$t('end')} ${$t('date')}`"
          >
            <FlotoDatePicker
              v-model="task.timeRange.endTime"
              :min-date="task.timeRange.startTime"
            />
          </FlotoFormItem>
        </MCol>
      </MRow>
      <MRow>
        <MCol v-if="takeStatus" :sm="6" :size="12">
          <FlotoFormItem id="status-picker" :label="$t('status')">
            <FlotoStatusPicker
              v-model="task.statusId"
              mandatory
              :allow-clear="false"
              as-input
              :module-name="$constants.TASK"
            />
          </FlotoFormItem>
        </MCol>
        <MCol :sm="6" :size="12">
          <FlotoFormItem id="priority-picker" :label="$t('priority')">
            <FlotoPriorityPicker
              v-model="task.priorityId"
              :allow-clear="false"
              as-input
            />
          </FlotoFormItem>
        </MCol>
        <MCol :sm="6" :size="12">
          <FlotoFormItem
            id="notify-before-picker"
            :label="$t('notify_before')"
            :rules="notifyBeforeHoursValidations"
            :validation-label="`${$tc('notify_before')} ${$tc(
              task.notifyBeforeHoursTimeUnit,
              2
            )}`"
          >
            <FlotoEstimatedTimePicker
              v-model="task.notifyBeforeHours"
              :allowed-units="['hours', 'minutes', 'days']"
              :time-unit.sync="task.notifyBeforeHoursTimeUnit"
              as-input
            />
          </FlotoFormItem>
        </MCol>
        <MCol v-if="takeEstimatedTime" :sm="6" :size="12">
          <FlotoFormItem
            id="estimated-time-picker"
            :label="$t('estimated_time')"
            :rules="estimatedTimeValidations"
          >
            <FlotoEstimatedTimePicker
              v-model="task.estimatedTime"
              :allowed-units="['hours', 'minutes', 'days']"
              :time-unit.sync="task.estimatedTimeUnit"
              as-input
            />
          </FlotoFormItem>
        </MCol>
      </MRow>
      <FlotoFormItem
        id="description-input"
        type="textarea"
        :rows="3"
        :label="$t('description')"
        rules="description"
      >
        <FlotoRichEditor
          v-model="task.description"
          :placeholder="$t('description')"
          :affix-toolbar="false"
        />
      </FlotoFormItem>
      <FlotoAttachment
        v-if="!hideAttachment"
        id="attachment-button"
        v-model="task.fileAttachemnts"
      />
    </MCol>
  </MRow>
</template>

<script>
import { getValidationRulesForEstimatedTime } from './utils'
export default {
  name: 'TaskForm',
  props: {
    task: { type: Object, required: true },
    takeRange: { type: Boolean, default: false },
    takeEstimatedTime: { type: Boolean, default: false },
    takeStatus: { type: Boolean, default: false },
    hideAttachment: { type: Boolean, default: false },
  },
  computed: {
    estimatedTimeValidations() {
      const validations = {
        required: true,
        nonzero: true,
        ...getValidationRulesForEstimatedTime(this.task.estimatedTimeUnit),
      }
      return validations
    },
    notifyBeforeHoursValidations() {
      const validations = {
        ...getValidationRulesForEstimatedTime(
          this.task.notifyBeforeHoursTimeUnit
        ),
      }
      return validations
    },
  },
}
</script>
