var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"flex flex-col flex-1"},[(_vm.addRelationTypes.length && !_vm.disabled)?_c(_vm.stickySearch ? 'MAffix' : 'div',{tag:"Component",attrs:{"offset-top":_vm.offsetTop},on:{"change":function($event){_vm.searchAffixed = $event}}},[_c('div',{staticClass:"relation-header",class:{ 'pt-2': _vm.searchAffixed }},[_c('div',{staticClass:"m-align-button"},[_c('FlotoDropdownPicker',{ref:"createAndRelateDropdown",staticClass:"mx-2",class:{ invisible: !(_vm.createRelationOptions.length && !_vm.disabled) },attrs:{"id":"createAndRelateDropdown","options":_vm.createRelationOptions},on:{"change":_vm.createAndRelateNew},scopedSlots:_vm._u([{key:"trigger",fn:function(ref){
var toggle = ref.toggle;
return [_c('MButton',{attrs:{"variant":"primary"},on:{"click":toggle}},[_vm._v(" "+_vm._s(_vm.$t('create_and_relate'))+" "),_c('MIcon',{staticClass:"mr-1",attrs:{"name":"chevron-down","size":"sm","transform":"shrink-2 right-4"}})],1)]}}],null,false,3766327051)}),_c('FlotoDropdownPicker',{ref:"addDropdown",class:{ invisible: !(_vm.addRelationTypes.length && !_vm.disabled) },attrs:{"id":"addDropdown","options":_vm.addRelationOptions},on:{"change":_vm.addNewRelation},scopedSlots:_vm._u([{key:"trigger",fn:function(ref){
var toggle = ref.toggle;
return [_c('MButton',{attrs:{"variant":"primary"},on:{"click":toggle}},[_vm._v(" "+_vm._s(_vm.$tc('add'))+" "+_vm._s(_vm.$tc('relation'))+" "),_c('MIcon',{staticClass:"mr-2",attrs:{"name":"chevron-down","size":"sm","transform":"shrink-2 right-4"}})],1)]}}],null,false,2578860567)}),_c('MDivider')],1)])]):_vm._e(),_c('MTab',{attrs:{"position":"left"},model:{value:(_vm.currentTab),callback:function ($$v) {_vm.currentTab=$$v},expression:"currentTab"}},_vm._l((_vm.leftSideTabOptions),function(tab){return _c('MTabPane',{key:tab.moduleName,attrs:{"tab":((tab.title) + " (" + (_vm.relationCount[tab.moduleName] || 0) + ")")}},[(tab.moduleName === _vm.currentTab)?_c('RelationContent',{key:_vm.renderCount,attrs:{"disabled":_vm.disabled,"source-module-name":_vm.subModuleName || _vm.moduleName,"relation-type":tab.moduleName === 'merged' ? 'child' : 'related',"destination-module-name":tab.moduleName === 'merged' ? _vm.$constants.REQUEST : tab.moduleName,"source-id":_vm.resourceId,"title":tab.title,"is-approval":_vm.isApproval},on:{"related-ids-received":function($event){_vm.alreadyRelatedIds = $event},"related-items-received":_vm.alreadyRelatedItemsReceived,"relation-removed":_vm.relationRemoved}}):_vm._e()],1)}),1),_c('FlotoDrawer',{attrs:{"open":_vm.showDrawer,"width":"65%"},on:{"hide":_vm.hideDrawer},scopedSlots:_vm._u([{key:"title",fn:function(){return [_vm._v(" "+_vm._s(_vm.$t('add'))+" "+_vm._s(_vm.$tc('relation', 2))+" ")]},proxy:true},{key:"actions",fn:function(ref){
var hide = ref.hide;
return [_c('MButton',{staticClass:"mx-1",attrs:{"id":"add-relation-btn","disabled":_vm.selectedItems.length === 0,"outline":"","loading":_vm.processing},on:{"click":_vm.handleAddRelations}},[_vm._v(" "+_vm._s(_vm.$t('add'))+" "+_vm._s(_vm.$tc('relation'))+" ")]),_c('MButton',{attrs:{"id":"cancel-btn","variant":"default"},on:{"click":hide}},[_vm._v(" "+_vm._s(_vm.$t('cancel'))+" ")])]}}])},[_c(_vm.selectionComponent,{tag:"component",attrs:{"selectable":"","module-name":_vm.selectedRelationType,"source-module-name":_vm.subModuleName || _vm.moduleName,"searchable":"","fetch-fn":_vm.fetchEligableRelations},on:{"selection-change":_vm.setSelectedItems,"asset-sub-module-change":_vm.handleAssetSubModuleChange}})],1),_c('FlotoDrawerForm',{attrs:{"open":_vm.showCreateDrawer,"width":_vm.selectedRelationType === _vm.$constants.SERVICE_CATALOG ? '90%' : '65%'},on:{"cancel":_vm.hideDrawer,"submit":_vm.handleCreateAndRelate},scopedSlots:_vm._u([{key:"header",fn:function(){return [_vm._v(" "+_vm._s(_vm.$t('create'))+" "+_vm._s(_vm.$tc(_vm.selectedRelationType))+" ")]},proxy:true},{key:"actions",fn:function(ref){
var hide = ref.hide;
var submit = ref.submit;
return [_c('MButton',{staticClass:"mx-1",attrs:{"id":"create-and-relate-btn","outline":"","disabled":_vm.selectedRelationType === _vm.$constants.SERVICE_CATALOG &&
          !_vm.creatingResource.serviceId,"loading":_vm.processing},on:{"click":submit}},[_vm._v(" "+_vm._s(_vm.$t('create_and_relate'))+" ")]),_c('MButton',{attrs:{"id":"cancel-btn","variant":"default"},on:{"click":hide}},[_vm._v(" "+_vm._s(_vm.$t('cancel'))+" ")])]}}])},[(_vm.allowCopyParentData)?_c('MRow',[_c('MCol',{staticClass:"mx-2 mb-4",attrs:{"size":12}},[_c('MCheckbox',{attrs:{"checked":_vm.copyDataFromParent},on:{"change":_vm.changeCopyDataFromParent}},[_vm._v(" "+_vm._s(_vm.$tc('copy_data_from_parent_request'))+" ")])],1)],1):_vm._e(),_c('FormRulesProvider',{key:_vm.selectedRelationType,attrs:{"module-name":_vm.selectedRelationType}},[(_vm.selectedRelationType)?_c(_vm.formComponent,{key:_vm.renderCreateFormCount,tag:"component",attrs:{"value":_vm.creatingResource,"module-name":_vm.selectedRelationType,"processing":_vm.processing,"use-template":!_vm.copyDataFromParent,"avoid-default-value":_vm.copyDataFromParent,"with-submit":false,"default-selected-assets":_vm.copyDataFromParent ? _vm.parentRequestSelectedAssets : [],"disabled":_vm.selectedRelationType === 'service_catalog',"available-asset-type":_vm.availableAssetTypeComputed},on:{"reset-form":_vm.handleResetForm,"templateSelected":_vm.handleTemplateSelected,"change":_vm.handleChangeCreatingResource}}):_vm._e()],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }