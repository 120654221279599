<template>
  <FlotoCrudContainer
    ref="crudContainerRef"
    :fetch-fn="getSlaHistory"
    :scrollable="false"
    :columns="tableColumns"
    :show-no-data="false"
    as-table
  >
    <template v-slot:form><span /></template>

    <template v-slot:slaName="{ item }">
      <td class="text-ellipsis">{{ item.slaName }}</td>
    </template>
    <template v-slot:slaType="{ item }">
      <td class="text-ellipsis">{{
        item.slaType
          ? (slaTypeOptions.find((o) => o.key === item.slaType) || {}).text
          : '---'
      }}</td>
    </template>
    <template v-slot:target="{ item }">
      <td class="text-ellipsis">{{
        item.target
          ? (slaTargateTypeOptions.find((o) => o.key === item.target) || {})
              .text
          : '---'
      }}</td>
    </template>
    <template v-slot:targetStatus="{ item }">
      <td class="text-ellipsis">{{
        item.targetStatus
          ? (
              slaTargateStatusOptions.find(
                (o) => o.key === item.targetStatus
              ) || {}
            ).text
          : '---'
      }}</td>
    </template>
    <template v-slot:updatedBy="{ item }">
      <td class="text-ellipsis">
        <FlotoUserDrawer :user-id="item.updatedBy" />
      </td>
    </template>
    <template v-slot:elapsedTime="{ item }">
      <td class="text-ellipsis">{{ item.elapsedTime | duration }}</td>
    </template>
    <template v-slot:dueIn="{ item }">
      <td class="text-ellipsis">{{ item.dueIn | duration }}</td>
    </template>
    <template v-slot:slaPercentage="{ item }">
      <td class="text-ellipsis">
        <div class="flex flex-col">
          {{ item.slaPercentage }}%
          <ProgressViewer
            :value="item.slaPercentage"
            type="line"
            :show-info="false"
            :stroke-color="strokeColor(item)"
          />
        </div>
      </td>
    </template>
    <template v-slot:overdue="{ item }">
      <td class="text-ellipsis">{{ item.overdue ? $tc('yes') : $tc('no') }}</td>
    </template>
    <template v-slot:startTime="{ item }">
      <td class="text-ellipsis">{{ item.startTime | datetime }}</td>
    </template>
    <template v-slot:lastPauseTime="{ item }">
      <td class="text-ellipsis">{{
        !item.lastPauseTime ? '---' : item.lastPauseTime | datetime
      }}</td>
    </template>
    <template v-slot:stopTime="{ item }">
      <td class="text-ellipsis">{{
        !item.stopTime ? '---' : item.stopTime | datetime
      }}</td>
    </template>
    <template v-slot:pauseDuration="{ item }">
      <td class="text-ellipsis">{{ item.pauseDuration | duration }}</td>
    </template>
    <template v-slot:breachedDuration="{ item }">
      <td class="text-ellipsis">{{ item.breachedDuration | duration }}</td>
    </template>
    <template v-slot:penaltyAmount="{ item }">
      <td class="text-ellipsis">
        <div v-if="item.penaltyAmount" class="flex">
          {{ `${item.penaltyAmount.toFixed(2)}` }}
          <CurrencyPicker :value="item.currencyId" text-only disabled />
        </div>
        <div v-else> --- </div>
      </td>
    </template>
    <template v-slot:technicianGroup="{ item }">
      <td class="text-ellipsis">
        <FlotoTechnicianGroupPicker
          v-if="item.technicianGroup"
          :value="item.technicianGroup"
          class="cursor-default"
          text-only
          placeholder="---"
          disabled
        />
        <span v-else>---</span>
      </td>
    </template>
    <template v-slot:operationalHourType="{ item }">
      <td class="text-ellipsis">{{
        item.operationalHourType
          ? (
              slaOperationalHourTypeOptions.find(
                (o) => o.key === item.operationalHourType
              ) || {}
            ).text
          : '---'
      }}</td>
    </template>
    <template v-slot:updateCount="{ item }">
      <td class="text-ellipsis">{{ item.updateCount || 0 }}</td>
    </template>
  </FlotoCrudContainer>
</template>

<script>
import Moment from 'moment'
import CurrencyPicker from '@components/data-picker/currency-picker'
import { colors } from '@utils/color'
import ProgressViewer from '@components/numeric-slider/progress-viewver'
import { getSlaHistory } from './sla-history-api'
import {
  slaTypeOptions,
  slaTargateTypeOptions,
  slaTargateStatusOptions,
  slaOperationalHourTypeOptions,
} from './option-map.js'

export default {
  name: 'SlaHistoryTab',
  components: { ProgressViewer, CurrencyPicker },
  props: {
    resourceId: { type: Number, required: true },
    moduleName: { type: String, required: true },
    stickySearch: { type: Boolean, default: false },
    offsetTop: { type: Number, default: 0 },
  },
  data() {
    this.Moment = Moment
    this.slaTypeOptions = slaTypeOptions()
    this.slaTargateTypeOptions = slaTargateTypeOptions()
    this.slaTargateStatusOptions = slaTargateStatusOptions()
    this.slaOperationalHourTypeOptions = slaOperationalHourTypeOptions()
    return {
      searchAffixed: false,
    }
  },
  computed: {
    tableColumns() {
      return [
        { name: `${this.$tc('name')}`, key: 'slaName', minWidth: '200px' },
        { name: `${this.$tc('type')}`, key: 'slaType' },
        { name: `${this.$tc('target')}`, key: 'target', minWidth: '150px' },
        {
          name: `${this.$tc('status')}`,
          key: 'targetStatus',
          minWidth: '100px',
        },
        {
          name: `${this.$tc('updated_by')}`,
          key: 'updatedBy',
          minWidth: '100px',
        },
        {
          name: `${this.$tc('elapsed_time')}`,
          key: 'elapsedTime',
          minWidth: '200px',
        },
        { name: `${this.$tc('due_in')}`, key: 'dueIn', minWidth: '150px' },
        {
          name: `${this.$tc('sla')} ${this.$tc('percentage')}`,
          key: 'slaPercentage',
          minWidth: '200px',
        },
        {
          name: `${this.$tc('overdue')}`,
          key: 'overdue',
        },
        {
          name: `${this.$tc('start_time')}`,
          key: 'startTime',
          minWidth: '200px',
        },
        {
          name: `${this.$tc('last_pause_time')}`,
          key: 'lastPauseTime',
          minWidth: '200px',
        },
        {
          name: `${this.$tc('stop_time')}`,
          key: 'stopTime',
          minWidth: '200px',
        },
        {
          name: `${this.$tc('pause_duration')}`,
          key: 'pauseDuration',
          minWidth: '200px',
        },
        {
          name: `${this.$tc('breached_duration')}`,
          key: 'breachedDuration',
          minWidth: '200px',
        },
        {
          name: `${this.$tc('slaModule.penalty')} ${this.$tc('amount')}`,
          key: 'penaltyAmount',
          minWidth: '200px',
        },
        {
          name: `${this.$tc('technician')} ${this.$tc('group')}`,
          key: 'technicianGroup',
          minWidth: '150px',
        },
        {
          name: `${this.$tc('slaModule.operational_hours_type')}`,
          key: 'operationalHourType',
          minWidth: '200px',
        },
        {
          name: `${this.$tc('update_count')}`,
          key: 'updateCount',
          minWidth: '150px',
        },
      ]
    },
  },
  methods: {
    getSlaHistory(limit, offset) {
      return getSlaHistory(this.moduleName, this.resourceId, limit, offset)
    },
    strokeColor(item) {
      if (item.slaPercentage > 0 && item.slaPercentage <= 50) {
        return colors.success
      }
      if (item.slaPercentage > 50 && item.slaPercentage <= 75) {
        return colors['secondary-yellow']
      }
      if (item.slaPercentage > 75 && item.slaPercentage <= 100) {
        return colors.warning
      }
      if (item.slaPercentage > 100) {
        return colors.error
      }
    },
  },
}
</script>
