var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('MRow',_vm._b({staticClass:"h-100 ignore-mousetrap min-w-0"},'MRow',_vm.advancedValueInput ? { gutter: 0 } : {},false),[_c('MCol',{staticClass:"flex flex-col h-100 min-w-0"},[(_vm.operators.length)?_c('div',{staticClass:"flex-col flex"},[_c('MCol',[_c('div',{staticClass:"pt-2"},[_c('small',{staticClass:"text-neutral"},[_vm._v(_vm._s(_vm.$t('operator')))])]),_c('div',[(_vm.allOperators.length <= 1)?_c('div',_vm._l((_vm.allOperators),function(o){return _c('MCheckableTag',{key:o.value,class:{ disabled: o.disabled },attrs:{"checked":_vm.currentOperator.value === o.value},on:{"input":function($event){_vm.currentOperator = o}}},[_vm._v(" "+_vm._s(o.name)+" ")])}),1):_c('FlotoDropdownPicker',_vm._b({attrs:{"as-input":"","searchable":"","allow-clear":false,"options":_vm.allOperators,"value":_vm.currentOperator ? _vm.currentOperator.value : undefined},on:{"change":_vm.handleChange}},'FlotoDropdownPicker',_vm.$attrs,false))],1),_c('div',[_c('MDivider',{staticClass:"mb-1 mt-2"})],1)])],1):_vm._e(),_c('div',{staticClass:"flex-1 overflow-hidden",staticStyle:{"min-width":"300px"}},[(_vm.unaryOperators.indexOf(_vm.currentOperator.value) === -1)?_c('FlotoForm',{ref:"form",staticClass:"h-100 flex flex-col",attrs:{"layout":"vertical","show-notification":false},on:{"submit":_vm.formSubmitted},scopedSlots:_vm._u([{key:"submit",fn:function(ref){
var invalid = ref.invalid;
return [_c('div',{staticClass:"py-2"},[_c('MCol',{staticClass:"flex justify-end"},[_c('MButton',{attrs:{"disabled":invalid,"outline":"","size":"small","type":"submit"}},[_vm._v(" "+_vm._s(_vm.$t('done'))+" ")])],1)],1)]}}],null,false,3295123380)},[_c('FlotoScrollView',[(
              _vm.isDropdown ||
              _vm.fieldValueOperators.indexOf(_vm.currentOperator.value) >= 0
            )?_c('MCol',[_c('FlotoFormItem',{staticClass:"m-0 p-0",attrs:{"label":_vm.$t('value'),"rules":_vm.dropdownValuesValidation,"module-name":_vm.moduleName}},[_c('DropdownValues',{key:_vm.currentOperator.value,attrs:{"options":_vm.fieldValueOperators.indexOf(_vm.currentOperator.value) >= 0
                    ? _vm.source.fieldDetailsOptions
                    : _vm.options,"single-value-selection":['equal', 'not_equal' ].concat( _vm.fieldValueOperators).indexOf(
                    _vm.currentOperator.value
                  ) >= 0},model:{value:(_vm.selectedValue),callback:function ($$v) {_vm.selectedValue=$$v},expression:"selectedValue"}})],1)],1):(_vm.isDateSpecialField)?_c('MCol',[_c('CreatedTime',{attrs:{"value":_vm.selectedValue,"to-value":_vm.selectedToValue,"operator":_vm.currentOperator,"type":_vm.type,"module-name":_vm.moduleName},on:{"update:value":function($event){_vm.selectedValue=$event},"update:toValue":function($event){_vm.selectedToValue=$event},"update:to-value":function($event){_vm.selectedToValue=$event}}})],1):_c('MCol',[_c('FlotoFormItem',{staticClass:"m-0 p-0",attrs:{"vid":"fromDateTime","validation-label":_vm.$t('value'),"rules":_vm.validationRules}},[(_vm.fieldValueOperators.indexOf(_vm.currentOperator.value) >= 0)?_c('FlotoDropdownPicker',_vm._b({attrs:{"id":"field-value-input","as-input":"","searchable":"","options":_vm.source.fieldDetailsOptions},model:{value:(_vm.selectedValue),callback:function ($$v) {_vm.selectedValue=$$v},expression:"selectedValue"}},'FlotoDropdownPicker',_vm.$attrs,false)):_c('FlotoValueInput',_vm._b({attrs:{"input-type":_vm.type === 'dateTime' || _vm.type === 'date'
                    ? _vm.durationOperators.indexOf(_vm.currentOperator.value) >= 0
                      ? 'duration'
                      : 'date'
                    : _vm.type,"value-type":_vm.valueType,"day-time-trigger-style":{ 'mb-6': true },"get-popup-container":_vm.getPopupContainer,"placeholder":_vm.placeholder,"module-name":_vm.moduleName,"operator":_vm.currentOperator.value,"always-text-mode":"","additional-options":_vm.additionalOptions,"available-asset-type":_vm.availableAssetType || _vm.searchBarContext.availableAssetType,"auto-focus":"","multiple":""},model:{value:(_vm.selectedValue),callback:function ($$v) {_vm.selectedValue=$$v},expression:"selectedValue"}},'FlotoValueInput',_vm.fromInputSpecificProps,false))],1),(_vm.betweenOperators.indexOf(_vm.currentOperator.value) >= 0)?[_c('div',{staticClass:"mb-4 flex items-center justify-center"},[_vm._v(" "+_vm._s(_vm.$t('to'))+" ")]),_c('FlotoFormItem',{staticClass:"m-0 p-0",attrs:{"vid":"toDateTime","validation-label":_vm.$t('value'),"rules":Object.assign({}, _vm.validationRules,
                  (_vm.type === 'number'
                    ? // eslint-disable-next-line
                      { min_value: _vm.selectedValue + 1, nonzero: false }
                    : {}))}},[_c('FlotoValueInput',_vm._b({attrs:{"input-type":_vm.type,"get-popup-container":_vm.getPopupContainer,"value-type":_vm.valueType,"placeholder":_vm.placeholder,"module-name":_vm.moduleName,"operator":_vm.currentOperator.value,"additional-options":_vm.additionalOptions,"available-asset-type":_vm.availableAssetType || _vm.searchBarContext.availableAssetType,"auto-focus":"","multiple":""},model:{value:(_vm.selectedToValue),callback:function ($$v) {_vm.selectedToValue=$$v},expression:"selectedToValue"}},'FlotoValueInput',_vm.inputSpecificProps,false))],1)]:_vm._e()],2)],1)],1):_vm._e()],1)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }