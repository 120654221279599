<template>
  <MTag rounded variant="neutral-lighter" @close="handleRemove">
    <template v-slot:confirm-title>
      {{ $t('confirm_remove_item', { item: source.name || value.value }) }}
    </template>
    <div class="tag-content mx-1">
      <MPopover
        ref="popover"
        :placement="popoverPlacment"
        transition-name="slide-up"
        overlay-class-name="picker-overlay"
        :default-open="!hasValue"
        destroy-tooltip-on-hide
        @hide="cancelSelection"
      >
        <template v-slot:trigger>
          <span :title="tagTitle">
            {{ source.name }}
          </span>
        </template>
        <MRow class="h-100 ignore-mousetrap min-w-0">
          <!-- dummy input for focus on mount -->
          <!-- <input ref="dummyInputRef" style="width: 0; border: none" /> -->
          <MCol class="flex flex-col h-100 min-w-0">
            <div class="flex-1 overflow-hidden my-3" style="min-width: 300px">
              <div class="flex flex-col">
                <FlotoScrollView>
                  <SeviceCatalogCustomField
                    :get-popup-container="getPopupContainer"
                    :module-name="moduleName"
                    v-bind="$attrs"
                    @change="$emit('change', $event)"
                  />
                </FlotoScrollView>
              </div>
            </div>
          </MCol>
        </MRow>
      </MPopover>
    </div>
  </MTag>
</template>

<script>
import { authComputed } from '@state/modules/auth'
import SeviceCatalogCustomField from './value-picker/service-catalog-custom-field.vue'

export default {
  name: 'AdvancedValueSelector',
  components: { SeviceCatalogCustomField },
  inject: ['searchBarContext'],
  model: {
    event: 'change',
  },
  props: {
    source: { type: Object, required: true },
    moduleName: { type: String, required: true },
    value: {
      type: Object,
      default() {
        return {
          operator: {},
        }
      },
    },
    availableAssetType: { type: [String, Array], default: undefined },
  },
  computed: {
    ...authComputed,
    popoverPlacment() {
      return this.isRtl ? 'bottomRight' : 'bottomLeft'
    },
    tagTitle() {
      return `${this.source.name}`
    },
    hasValue() {
      return this.value.value !== undefined
      // return Boolean(this.value.value)
    },
  },
  methods: {
    handleRemove() {
      if (this.hasValue) {
        this.$emit('remove')
        return
      }
      this.$emit('cancel')
    },
    cancelSelection() {
      this.$emit('cancel')
    },
    getPopupContainer() {
      const e = this.$el.closest('.__panel')
      if (e) {
        return e
      }
      return document.body
    },
  },
}
</script>
<style lang="less" scoped>
.@{ant-prefix}-tag {
  .tag-content {
    max-width: 650px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}
</style>
