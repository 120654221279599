export function transformHierarchyConfig(config) {
  return {
    id: config.id,
    allowedRequestLeafNode: config.allowedRequestLeafNode,
    allowedProblemLeafNode: config.allowedProblemLeafNode,
    allowedChangeLeafNode: config.allowedChangeLeafNode,
    allowedReleaseLeafNode: config.allowedReleaseLeafNode,
    reportHierarchy: config.reportHierarchy,
    requestDefaultCategoryPrefix: config.requestDefaultCategoryPrefix,
    requestStartingNumber: config.requestStartingNumber,
    curruentRequestNumber: config.curruentRequestNumber,
  }
}

export function transformHierarchyConfigForServer(config) {
  return {
    allowedRequestLeafNode: config.allowedRequestLeafNode,
    allowedProblemLeafNode: config.allowedProblemLeafNode,
    allowedChangeLeafNode: config.allowedChangeLeafNode,
    allowedReleaseLeafNode: config.allowedReleaseLeafNode,
    reportHierarchy: config.reportHierarchy,
    requestDefaultCategoryPrefix: (
      config.requestDefaultCategoryPrefix || ''
    ).toUpperCase(),
    requestStartingNumber: config.requestStartingNumber,
    curruentRequestNumber: config.curruentRequestNumber,
  }
}
