import { render, staticRenderFns } from "./keyboard-shortcut-helper.vue?vue&type=template&id=72401c73&scoped=true&"
import script from "./keyboard-shortcut-helper.vue?vue&type=script&lang=js&"
export * from "./keyboard-shortcut-helper.vue?vue&type=script&lang=js&"
import style0 from "./keyboard-shortcut-helper.vue?vue&type=style&index=0&id=72401c73&prod&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "72401c73",
  null
  
)

export default component.exports